<template>
  <div class="tutorial-screen-inner align-items-center">
    <div class="tutorial-screen-left">
      <img src="/imgs/import-tutorial-step2.png" alt="Import Tutorial" />
    </div>
    <div class="tutorial-screen-right">
      <h3 class="sub-title">{{ info.subTitle }}</h3>
      <h3 class="screen-title">{{ info.title }}</h3>
      <div v-for="(item, index) in info.items" :key="index" class="item">
        <div>
          <h3 class="title">{{ item.title }}</h3>
          <p class="desc" v-for="desc in item.descs" :key="desc">
            {{ desc }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      info: {
        subTitle: "NEUER IMPORT",
        title: "Hochladen einer korrekten CSV Datei",
        items: [
          {
            descs: [
              "Links Anzeige der VME weit geltenden Feldnamen",
              "Rechts bitte entsprechende Spalte aus CSV Datei zuordnen",
              "! Jeder Spaltenname darf nur einmal zugeordnet werden."
            ]
          }
        ]
      }
    };
  }
};
</script>

<style lang="scss">
.tutorial-screen {
  &-inner {
    padding-right: 40px;
    padding-left: 65px;
    display: flex;
  }
  &-left {
    min-width: 420px;
    img {
      width: 100%;
    }
  }
  &-right {
    margin-left: 65px;
    .screen-title {
      font-size: 30px;
      line-height: 36px;
      text-transform: uppercase;
      margin-bottom: 32px;
      color: #555555;
    }
    .sub-title {
      font-size: 18px;
      line-height: 22px;
      color: #ee7f00;
    }
    .item {
      margin-bottom: 30px;
      color: #555;
      .title {
        font-size: 16px;
      }
      .desc {
        font-size: 14px;
        margin-top: 7px;
      }
    }
  }
}
</style>